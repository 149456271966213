import React from "react";
import { graphql } from "gatsby";
import BlogPageTemplate from "../../templates/blog";

const environment = {
  lang: "en",
  path: "/en/blog",
  altHrefLang: [
    {
      lang: "nl",
      path: "/blog",
    },
    {
      lang: "en",
      path: "/en/blog",
    },
  ],

  theme: "orange",
};

const BlogPageEn = (props) => {
  return <BlogPageTemplate environment={environment} {...props} />;
};
export default BlogPageEn;

export const query = graphql`
  query BlogPageEnQuery {
    articles: allContentfulArticle(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: [updatedAt], order: [DESC] }
    ) {
      edges {
        node {
          title
          slug
          node_locale
          contentful_id
          createdAt
          updatedAt
          description {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 120)
            }
          }
          image {
            main: gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
          __typename
        }
      }
    }
  }
`;
